var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promotion-page"},[_c('header',[_c('h1',[_vm._v(_vm._s(_vm.event.locale.title)+" ")])]),_c('section',{staticClass:"container info"},[_c('div',{staticClass:"row mt-80"},[_c('div',{staticClass:"image col-md-6 pr-16"},[_c('img',{attrs:{"src":_vm.event.locale.image.preview,"alt":""}})]),_c('div',{staticClass:"text col-md-6 pl-16"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('h3',{staticClass:"title-heading"},[_vm._v(_vm._s(_vm.event.locale.secondTitle))]),_c('h5',{},[_vm._v(_vm._s(_vm.event.locale.date))])]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.event.locale.preview_text)}})])])]),_c('section',{staticClass:"winners-photos"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title text-center"},[_vm._v(_vm._s(_vm.localize('akciya')['winnersPhotos']))]),_c('div',{staticClass:"row"},_vm._l((_vm.event.image),function(photoVideo,index){return _c('div',{key:index,staticClass:"col-md-3 mb-16"},[(photoVideo.type === 'image')?_c('div',{staticClass:"card",style:({
                backgroundImage: `url('${photoVideo.src}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }),attrs:{"data-fancybox":"","data-src":photoVideo.src}}):_c('fragment',[_c('video',{attrs:{"id":"winnersVideo","controls":"","width":"100%","height":"100%","poster":photoVideo.poster}},[_c('source',{attrs:{"src":photoVideo.src,"type":"video/mp4"}})]),_c('div',{staticClass:"video-play-button"},[(!_vm.isVideoPlaying)?_c('span',{on:{"click":_vm.play}},[_c('svg',{attrs:{"width":"58","height":"58","viewBox":"0 0 58 58","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"filter":"url(#filter0_b_32_13644)"}},[_c('circle',{attrs:{"cx":"29","cy":"29","r":"29","fill":"white","fill-opacity":"0.6"}})]),_c('path',{attrs:{"d":"M38.1218 27.2729L24.7577 19.4772C23.4244 18.6994 21.75 19.6611 21.75 21.2047V36.7961C21.75 38.3397 23.4244 39.3015 24.7577 38.5237L38.1218 30.728C39.4448 29.9562 39.4448 28.0446 38.1218 27.2729Z","fill":"#1E1E1E"}}),_c('defs',[_c('filter',{attrs:{"id":"filter0_b_32_13644","x":"-20","y":"-20","width":"98","height":"98","filterUnits":"userSpaceOnUse","color-interpolation-filters":"sRGB"}},[_c('feFlood',{attrs:{"flood-opacity":"0","result":"BackgroundImageFix"}}),_c('feGaussianBlur',{attrs:{"in":"BackgroundImage","stdDeviation":"10"}}),_c('feComposite',{attrs:{"in2":"SourceAlpha","operator":"in","result":"effect1_backgroundBlur_32_13644"}}),_c('feBlend',{attrs:{"mode":"normal","in":"SourceGraphic","in2":"effect1_backgroundBlur_32_13644","result":"shape"}})],1)])])]):_c('span',{staticClass:"pause",on:{"click":_vm.play}},[_c('span',[_vm._v("| |")])])])])],1)}),0),_c('swiper',{staticClass:"swiper-wrapper mobile",attrs:{"id":"images","options":_vm.swiperOptions}},_vm._l((_vm.event.image),function(photoVideo,index){return _c('swiper-slide',{key:index,staticClass:"swiper-slide"},[(photoVideo.type === 'image')?_c('div',{style:({
                height: '171px',
                backgroundImage: `url('${photoVideo.src}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: '8px',
                backgroundPosition: 'center',

              }),attrs:{"data-fancybox":"","data-src":photoVideo.src}}):_c('video',{staticStyle:{"object-fit":"cover","border-radius":"8px","height":"170px"},attrs:{"controls":"","width":"100%","height":"100%","poster":photoVideo.poster}},[_c('source',{attrs:{"src":photoVideo.src,"type":"video/mp4"}})])])}),1)],1)]),_c('section',{staticClass:"list-winners"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title text-center"},[_vm._v(_vm._s(_vm.localize('akciya')['winnersText']))]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('ol',{staticClass:"list",staticStyle:{"line-height":"30px"}},_vm._l((_vm.event.winners),function(winner,index){return _c('li',{key:index},[_c('div',{staticClass:"winner"},[_c('p',[_vm._v(_vm._s(winner.name))])]),_c('div',{staticClass:"gift"},[_c('span',[_vm._v(_vm._s(_vm.localize('akciya')['giftText']))]),_vm._v(" "+_vm._s(winner.gift)+" ")])])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }