<template>
  <div class="promotion-page">
    <header>
      <h1>{{ event.locale.title }} </h1>
    </header>

    <section class="container info">
      <div class="row mt-80">
        <div class="image col-md-6 pr-16">
          <img :src="event.locale.image.preview" alt="">
        </div>

        <div class="text col-md-6 pl-16">
          <div class="d-flex justify-content-start align-items-center">
            <h3 class="title-heading">{{ event.locale.secondTitle }}</h3>
            <h5 class="">{{ event.locale.date }}</h5>
          </div>
          <p v-html="event.locale.preview_text"></p>
        </div>
      </div>
    </section>

    <section class="winners-photos">
      <div class="container">
        <h1 class="title text-center">{{ localize('akciya')['winnersPhotos'] }}</h1>

        <div class="row">
          <div
            v-for="(photoVideo, index) in event.image"
            :key="index"
            class="col-md-3 mb-16"
          >

            <div data-fancybox :data-src="photoVideo.src"
                 v-if="photoVideo.type === 'image'"
                 class="card"
                 :style="{
                  backgroundImage: `url('${photoVideo.src}')`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }"
            ></div>
            <fragment v-else>
              <video
                id="winnersVideo"
                controls
                width="100%"
                height="100%"
                :poster="photoVideo.poster"
              >
                <source :src="photoVideo.src" type="video/mp4">
              </video>
              <div class="video-play-button">
                <span v-if="!isVideoPlaying" @click="play">
                  <svg width="58" height="58" viewBox="0 0 58 58" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_b_32_13644)">
                    <circle cx="29" cy="29" r="29" fill="white" fill-opacity="0.6" />
                  </g>
                  <path
                    d="M38.1218 27.2729L24.7577 19.4772C23.4244 18.6994 21.75 19.6611 21.75 21.2047V36.7961C21.75 38.3397 23.4244 39.3015 24.7577 38.5237L38.1218 30.728C39.4448 29.9562 39.4448 28.0446 38.1218 27.2729Z"
                    fill="#1E1E1E" />
                  <defs>
                    <filter id="filter0_b_32_13644" x="-20" y="-20" width="98" height="98" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur in="BackgroundImage" stdDeviation="10" />
                      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_32_13644" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_32_13644" result="shape" />
                    </filter>
                  </defs>
                </svg>
                </span>
                <span v-else class="pause" @click="play">
                  <span>| |</span>
                </span>
              </div>
            </fragment>

          </div>
        </div>

        <swiper id="images" class="swiper-wrapper mobile" :options="swiperOptions">
          <swiper-slide
            class="swiper-slide"
            v-for="(photoVideo, index) in event.image"
            :key="index"
          >
            <div data-fancybox :data-src="photoVideo.src" v-if="photoVideo.type === 'image'" :style="{
                  height: '171px',
                  backgroundImage: `url('${photoVideo.src}')`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: '8px',
                  backgroundPosition: 'center',

                }">
            </div>
            <video
              v-else
              style="object-fit: cover; border-radius: 8px; height: 170px"
              controls
              width="100%"
              height="100%"
              :poster="photoVideo.poster"
            >
              <source :src="photoVideo.src" type="video/mp4">
            </video>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="list-winners">
      <div class="container">
        <h1 class="title text-center">{{ localize('akciya')['winnersText'] }}</h1>
        <div class="d-flex justify-content-center">
          <ol class="list" style="line-height: 30px">
            <li
              v-for="(winner, index) in event.winners"
              :key="index"
            >
              <div class="winner">
                <!--                <div class="number">-->
                <!--                  {{ index + 1 }}-->
                <!--                  <div class="circle"></div>-->
                <!--                </div>-->
                <p>{{ winner.name }}</p>
              </div>
              <div class="gift">
                <span>{{ localize('akciya')['giftText'] }}</span> {{ winner.gift }}
              </div>
            </li>
          </ol>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';

import localize from '../filters/localize.filter';
import loadingMixin from '../mixins/loading.mixin';
import winnersPhotosVideos from '../data/winners-photos-videos';

import akciyaImageUz from '../assets/img/akciya-uz.png';
import akciyaImageRu from '../assets/img/akciya-ru.png';

import dataCard from '../data/data-card';
import { Fancybox } from '@fancyapps/ui';
// import "fancyapps/ui/dist/fancybox.css";

// import 'viewerjs/dist/viewer.css';
// import Viewer from 'viewerjs';

export default {
  name: 'StockPage',
  props: ['slug'],
  mixins: [loadingMixin],
  data: () => ({
    phone: null,
    resultPhone: null,
    userStatus: null,
    events: dataCard.lastNews,
    event: null,
    winnersPhotosVideos,
    cardStyle: {},
    viewer: null,
    isVideoPlaying: false,
    swiperOptions: {
      slidesPerView: 6,
      spaceBetween: 32,
      freemode: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
        // when window width is >= 576px
        576: {
          slidesPerView: 1.1,
          spaceBetween: 30,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
      },
    },
  }),
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    akciyaImage() {
      console.log(this.$i18n.locale);
      return this.$i18n.locale === 'ru'
        ? akciyaImageRu
        : akciyaImageUz;
    },
  },
  methods: {
    localize,
    play() {
      const videoPlayer = document.querySelector('#winnersVideo');

      if (videoPlayer.paused) {
        this.isVideoPlaying = true;
        videoPlayer.play();
      } else {
        this.isVideoPlaying = false;
        videoPlayer.pause();
      }
    },
  },
  created() {
    this.event = this.events.find(lastNew => lastNew.id === Number(this.slug));
  },
  mounted() {
    Fancybox.bind('[data-fancybox="gallery"]', {
      caption: function (fancybox, carousel, slide) {
        return (
          `${slide.index + 1} / ${carousel.slides.length} <br />` + slide.caption
        );
      },
    });
    // caption: function (fancybox, carousel, slide) {
    //   return (
    //           `${slide.index + 1} / ${carousel.slides.length} <br />` + slide.caption
    //   );
    // },

  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/css/bussiness.scss';

  @mixin title($fs: 32px, $fw: 900, $lh: 40px, $color: $black) {
    font-size: $fs;
    font-weight: $fw;
    line-height: $lh;
    color: $color;
  }

  .title-heading {
    margin-right: 24px;
    font-size: 29px;
    font-weight: bold;
  }

  .promotion-page {
    padding-bottom: 100px;

    header {
      text-align: center;
      padding: 56px 0;
      background: $grey url('../assets/icons/Vector.svg') no-repeat calc(50% - 120px) 37px;

      h1 {
        @include title(40px);
        @media (max-width: $mobile) {
          @include title(24px);
        }
      }
    }

    section.info {
      .image {
        img {
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          border-radius: 8px;
          width: 100%;
        }
      }

      .text {
        /*margin-top: 36px;*/

        h1 {
          @include title(40px);
          @media (max-width: $mobile) {
            @include title(24px);
            margin-top: 16px;
          }
        }

        p {
          margin-top: 16px;
          line-height: 24px;
        }
      }
    }

    section.winners-photos {
      margin-top: 100px;
      @media (max-width: $mobile) {
        margin-top: 42px;
      }

      .swiper-wrapper {
        display: none;
        @media (max-width: $mobile) {
          margin-top: 8px;
          display: block;
        }
      }

      h1 {
        @include title();
        @media (max-width: $mobile) {
          @include title(24px)
        }
      }

      .row {
        margin-top: 32px;
        @media (max-width: $mobile) {
          margin-top: 8px;
          display: none;
        }

        .card {
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          border-radius: 8px;
          height: 171px;
        }

        video {
          object-fit: cover;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          border-radius: 8px;
          position: relative;
        }

        .video-play-button {
          position: absolute;
          width: 100%;
          height: 65%;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          cursor: pointer;

          .pause {
            width: 58px;
            height: 58px;
            background-color: rgba($color: $white, $alpha: 0.6);
            backdrop-filter: blur(20px);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            -webkit-backdrop-filter: blur(20px);
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: $black;
              font-weight: 900;
              font-size: 20px;
            }
          }
        }
      }
    }

    section.list-winners {
      margin-top: 100px;
      @media (max-width: $mobile) {
        margin-top: 42px;
      }

      h1 {
        @include title();
        @media (max-width: $mobile) {
          @include title(24px)
        }
      }

      .list {
        width: 80%;
        margin-top: 24px;
        @media (max-width: $mobile) {
          width: 100%;
          margin-top: 16px;
        }

        li {
          background-color: $grey;
          margin-bottom: 8px;
          padding: 12px 16px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          border-radius: 8px;
          display: flex;
          @media (max-width: $mobile) {
            display: block;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .winner {
            width: 50%;
            display: flex;
            @media (max-width: $mobile) {
              width: 100%;
            }

            .number {
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;
              width: 32px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 8px;
              background-color: #FFDE39;
              position: relative;

              .circle {
                position: absolute;
                top: 4px;
                left: 4px;
                width: 24px;
                height: 24px;
                border: 1.5px solid #F9A74E;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
              }
            }

            p {
              font-weight: 700;
            }
          }

          .gift {
            width: 50%;
            @media (max-width: $mobile) {
              width: 100%;
            }

            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
</style>
