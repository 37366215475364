export default [
  {
    type: 'image',
    src: 'https://picsum.photos/id/1/280/171'
  },
  {
    type: 'video',
    src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
    poster: 'https://picsum.photos/id/6/280/171'
  },
  {
    type: 'image',
    src: 'https://picsum.photos/id/2/280/171'
  },
  {
    type: 'image',
    src: 'https://picsum.photos/id/4/280/171'
  },
]